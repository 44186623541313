<template>

    <div id="top" >
      <form enctype="multipart/form-data" v-on:submit.prevent="sendHttpData" ref="sendForm">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-2" style=" margin: auto; text-align: right">
            <label  :class="{ 'custom-file-upload': base64 == null, 'custom-file-upload-disabled': base64 != null }">
              <input :disabled="base64 != null" ref="uploadBtn" @change="handleFileChange" type="file" multiple accept="image/*" class="input-file"
                     name="file" id="file">

              {{ base64 == null ? 'Selecciona imagen' : 'Imagen seleccionada' }}

            </label>

            <br/>
          </div>
          <div class="col-2" style=" margin: auto; padding: 5px; text-align: center">
            <input
                style="width: 100%"
                class="form-control input"
                aria-label="With textarea"
                placeholder="Agrega mensaje"
                v-model="message"
            />
          </div>

          <div class="col-2" style=" margin: auto; text-align: left">
            <input :disabled="message == null && base64 == null" id="submit" type="submit" value="Enviar"/>
            <input :disabled="message == null && base64 == null" id="reset1" type="reset" @click="reset()" value="Limpiar"/>
          </div>
        </div>


      </form>
    </div>

    <div id="term-defs" >
<div class="box" style="margin-top: 10px">
  <div v-for="event in events" :key="event" class="card">

    <img  v-if="event.image" style="height: 200px; width: 300px" :src="event.image">
    <div v-if="event.message" class="container">{{ event.message }}</div>
  </div>
</div>



    </div>


</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      events: [],
      newEvents: [],
      interval: null,
      connection: null,
      message: null,
      base64: null,
      FILES: null
    };
  },
  methods: {
    getEvents() {
      fetch("https://c2uac76c77.execute-api.us-east-1.amazonaws.com/default/data/all")
          .then((response) => response.json())
          .then((json) => {
            for (const j of json) {
              this.addMessage(j)
            }
          });
    },
    getNews() {
      this.events = this.events.concat(this.newEvents)
      fetch("https://c2uac76c77.execute-api.us-east-1.amazonaws.com/default/data")
          .then((response) => response.json())
          .then((json) => {
            this.newEvents = json
          });
    },

    addMessage: function (message) {
      this.events.unshift(message)
    },

    sendMessage() {
      const data = JSON.stringify({
        action: "sendMessage",
        message: {
          message: this.message,
          image: this.base64
        },
      })

      this.connection.send(data)
    },

    sendHttpData() {

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
              message: this.message,
              image: this.base64
            }
        )
      };

      fetch("https://c2uac76c77.execute-api.us-east-1.amazonaws.com/default/data", requestOptions)
      this.$refs.sendForm.reset()
      this.message = null
      this.base64 = null
    },

    reset() {
      console.log('reset')
      this.$refs.sendForm.reset()
      this.message = null
      this.base64 = null
    },

    createBase64Image: function (FileObject) {
      let self = this;
      const reader = new FileReader();
      reader.onloadend = (e) => {
        self.base64 = e.target.result
      }
      reader.readAsDataURL(FileObject);

    },

    handleFileChange(e) {
      this.FILES = e.target.files[0];
      this.createBase64Image(this.FILES)

    }
  },

  created() {
    var ref = this
    this.getEvents();
    /*this.interval = setInterval(()=>{
      this.getNews()
    }, 10000) */


    this.connection = new WebSocket("wss://9plxl3e6aj.execute-api.us-east-1.amazonaws.com/production");
    this.connection.onmessage = function (dataEvent) {
      ref.addMessage(JSON.parse(dataEvent.data))
    }

    this.connection.onopen = function (dataEvent) {
      console.log("Successfully connected to the socket " + dataEvent.message)
    }

  },

}
</script>


<style>

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid white;
  background-color: white;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: black;
}


label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding:0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#content {
  width: 100%;
  height: 100%;
}

#top {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
}

#term-defs {
  padding: 16px;
  margin-top: 30px;
  height: auto; /* Used in this example to enable scrolling */
}


input[type=button], input[type=submit], input[type=reset] {
  background-color: white;
  border: none;
  color: black;

  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}

input[type=button]:disabled,
input[type=submit]:disabled,
input[type=button]:disabled,
input[type=reset][disabled],
input[type=submit][disabled],
input[type=reset][disabled]
{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: auto;
}

.custom-file-upload-disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: auto;
  color: #666666;
}

.box {
  display: flex;
  flex-wrap: wrap;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 300px;
  margin: 10px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 2px 16px;
}

</style>
